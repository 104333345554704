$(function () {
    $(document).ready(function () {

        $.fn.equivalent = function () {
            var $blocks = $(this),
                maxH = $blocks.eq(0).height();
            $blocks.each(function () {
                maxH = ($(this).height() > maxH) ? $(this).height() : maxH;
            });
            $blocks.height(maxH);
        };

        $('.b-about__footer').on('click', function () {

            if ($(window).width() > 991) {
                return false;
            }

            $parent = $(this).parent('.b-about');
            $content = $parent.find('.content');
            var heightElem = 0;

            $content.children().each(function (i, elem) {
                heightElem = heightElem + $(this).outerHeight(true);
            });

            if ($parent.is('.in')) {
                $content.removeAttr('style');
                $parent.removeClass('in');
            }
            else {
                $content.css({ height: heightElem });
                $parent.addClass('in');
            }
        })
    })

    $(window).resize(function () {
        $about = $(document).find('.b-about');
        $content = $about.find('.content');

        if ($(window).width() > 991) {
            if ($about.is('.in')) {
                $content.removeAttr('style');
                $about.removeClass('in');
            }
        }
    });
});